import {
    AbstractMesh,
    ArcRotateCamera,
    Color4,
    Engine,
    HemisphericLight,
    ISceneLoaderAsyncResult,
    Scene,
    SceneLoader,
    Vector3,
    Viewport,
} from '@babylonjs/core';
import '@babylonjs/loaders';
import { MANMODELURL, WOMANMODELURL } from './const';

const sizes = {
    width: window.innerWidth / 2,
    height: window.innerHeight,
};
export class OutfitsScene {
    engine: Engine;
    scene: Scene;
    models: { name?: string; meshes: AbstractMesh[] }[] = [];
    model!: AbstractMesh[];
    manModel!: ISceneLoaderAsyncResult;
    womanModel!: ISceneLoaderAsyncResult;

    constructor(private canvas: HTMLCanvasElement) {
        this.engine = new Engine(this.canvas, true);
        this.scene = this.createScene();

        this.engine.setSize(sizes.width, sizes.height);

        this.engine.setHardwareScalingLevel(1);
        this.engine.setViewport(new Viewport(0, 0, sizes.width, sizes.height));

        this.engine.runRenderLoop(() => {
            this.scene.render();
        });

        window.addEventListener('resize', () => {
            this.engine.resize();
        });
    }

    createScene(): Scene {
        const scene = new Scene(this.engine);
        scene.clearColor = new Color4(0, 0, 0, 0);

        // const camera = new FreeCamera('camera', new Vector3(0, 1, -3), scene)
        const camera = new ArcRotateCamera(
            'camera',
            Math.PI / 2,
            Math.PI / 2,
            5,
            new Vector3(0, 0.8, 0),
        );
        camera.lowerRadiusLimit = 4; // 最小缩放值
        camera.upperRadiusLimit = 4; // 最大缩放值
        camera.panningDistanceLimit = 0; // 禁用右键平移
        camera.panningAxis = new Vector3(0, 0, 0); // 禁用右键平移

        // 设置上下旋转角度限制
        camera.lowerBetaLimit = Math.PI / 2; // 最小旋转角度
        camera.upperBetaLimit = Math.PI / 2; // 最大旋转角度

        camera.attachControl();
        camera.wheelPrecision = 20;

        camera.viewport = new Viewport(0.01, 0, 1 / 2, 1);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const light = new HemisphericLight('light', new Vector3(0, 1, 0), this.scene);
        // const box = MeshBuilder.CreateBox('box');

        return scene;
    }

    async importMeshes(url: string) {
        if (this.models.length <= 0) {
            this.manModel = await SceneLoader.ImportMeshAsync('', MANMODELURL);
            this.womanModel = await SceneLoader.ImportMeshAsync('', WOMANMODELURL);
            this.models.push(
                {
                    name: 'man',
                    meshes: this.manModel.meshes,
                },
                {
                    name: 'woman',
                    meshes: this.womanModel.meshes,
                },
            );
        }

        if (!url) return;
        if (url === MANMODELURL) {
            this.models[1].meshes[0].setEnabled(false);
            this.models[0].meshes[0].setEnabled(true);
            this.model = this.models[0].meshes;
        } else if (url === WOMANMODELURL) {
            this.models[0].meshes[0].setEnabled(false);
            this.models[1].meshes[0].setEnabled(true);
            this.model = this.models[1].meshes;
        }

        this.scene.getTransformNodeByName('node')?.dispose();

        this.initMesh(url);
    }

    initMesh(url: string) {
        let arr: string[];
        if (url === MANMODELURL) {
            arr = ['male_basics', 'male_head_0', 'male_shirt_0', 'male_pants_0', 'male_shoes_0'];
        } else if (url === WOMANMODELURL) {
            arr = [
                'female_basics',
                'female_head_0',
                'female_shirt_0',
                'female_pants_0',
                'female_shoes_0',
            ];
        }
        this.model.forEach((item) => {
            if (arr.every((current) => item.name !== current && item.parent?.name !== current)) {
                item.visibility = 0;
            }
            if (arr.some((current) => item.name === current || item.parent?.name === current)) {
                item.visibility = 1;
            }
        });
    }
}
