import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Outfits from '../views/outfits/index.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'outfits',
        component: Outfits,
    },
    {
        path: '/game',
        name: 'game',
        component: () => import(/* webpackChunkName: "game" */ '../views/exhibition/index.vue'),
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;

// 实现路由跳转
