<template>
    <router-view />
</template>

<style lang="scss">
html,
body,
canvas {
    min-width: 100vw;
    min-height: 100vh;
    overscroll-behavior: none;
}
canvas {
    width: 100vw;
    height: 100vh;
    touch-action: none;
    outline: none;
    display: block;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
