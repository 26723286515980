const modelData = {
    man: {
        head: [
            {
                name: 'male_head_0',
                label: '苦瓜脸',
                type: 'Mesh',
                url: 'images/man/t/1.png',
            },
            {
                name: 'male_head_1',
                label: '络腮胡',
                type: 'Mesh',
                url: 'images/man/t/2.png',
            },
            {
                name: 'male_head_2',
                label: '流行脸',
                type: 'Mesh',
                url: 'images/man/t/3.png',
            },
            {
                name: 'male_head_3',
                label: '圆圈胡',
                type: 'Mesh',
                url: 'images/man/t/4.png',
            },
            {
                name: 'male_head_4',
                label: '老实脸',
                type: 'Mesh',
                url: 'images/man/t/5.png',
            },
        ],
        shirt: [
            {
                name: 'male_shirt_0',
                label: '淡青外套',
                type: 'Mesh',
                url: 'images/man/sy/5.png',
            },
            {
                name: 'male_shirt_1',
                label: '淡青短袖',
                type: 'TransformNode',
                url: 'images/man/sy/1.png',
            },
            {
                name: 'male_shirt_2',
                label: '青蓝长袖',
                type: 'Mesh',
                url: 'images/man/sy/2.png',
            },
            {
                name: 'male_shirt_3',
                label: '上班族',
                type: 'Mesh',
                url: 'images/man/sy/3.png',
            },
            {
                name: 'male_shirt_4',
                label: '原谅色卫衣',
                type: 'TransformNode',
                url: 'images/man/sy/4.png',
            },
        ],
        pants: [
            {
                name: 'male_pants_0',
                label: '淡青短裤',
                type: 'TransformNode',
                url: 'images/man/kz/1.png',
            },
            {
                name: 'male_pants_1',
                label: '黑色长裤',
                type: 'TransformNode',
                url: 'images/man/kz/2.png',
            },
            {
                name: 'male_pants_2',
                label: '淡青长裤',
                type: 'TransformNode',
                url: 'images/man/kz/3.png',
            },
            {
                name: 'male_pants_3',
                label: '深蓝长裤',
                type: 'TransformNode',
                url: 'images/man/kz/4.png',
            },
            {
                name: 'male_pants_4',
                label: '原谅色长裤',
                type: 'TransformNode',
                url: 'images/man/kz/5.png',
            },
        ],
        shoes: [
            {
                name: 'male_shoes_0',
                label: '深蓝鞋',
                type: 'Mesh',
                url: 'images/man/xz/1.png',
            },
            {
                name: 'male_shoes_1',
                label: '酒红鞋',
                type: 'Mesh',
                url: 'images/man/xz/2.png',
            },
            {
                name: 'male_shoes_2',
                label: '淡青拖鞋',
                type: 'TransformNode',
                url: 'images/man/xz/3.png',
            },
            {
                name: 'male_shoes_3',
                label: '灰鞋',
                type: 'Mesh',
                url: 'images/man/xz/4.png',
            },
            {
                name: 'male_shoes_4',
                label: '淡青鞋',
                type: 'Mesh',
                url: 'images/man/xz/5.png',
            },
        ],
    },
    woman: {
        head: [
            {
                name: 'female_head_0',
                label: '头1',
                type: 'Mesh',
                url: '/images/woman/t/1.png',
            },
            {
                name: 'female_head_1',
                label: '头2',
                type: 'Mesh',
                url: '/images/woman/t/2.png',
            },
            {
                name: 'female_head_2',
                label: '头3',
                type: 'Mesh',
                url: '/images/woman/t/3.png',
            },
            {
                name: 'female_head_3',
                label: '头4',
                type: 'Mesh',
                url: '/images/woman/t/4.png',
            },
            {
                name: 'female_head_4',
                label: '头5',
                type: 'Mesh',
                url: '/images/woman/t/5.png',
            },
        ],
        shirt: [
            {
                name: 'female_shirt_0',
                label: '露脐装',
                type: 'TransformNode',
                url: '/images/woman/sy/1.png',
            },
            {
                name: 'female_shirt_1',
                label: '上衣2',
                type: 'TransformNode',
                url: '/images/woman/sy/2.png',
            },
            {
                name: 'female_shirt_2',
                label: '上衣3',
                type: 'Mesh',
                url: '/images/woman/sy/3.png',
            },
            {
                name: 'female_shirt_3',
                label: '上衣4',
                type: 'Mesh',
                url: '/images/woman/sy/4.png',
            },
            {
                name: 'female_shirt_4',
                label: '上衣5',
                type: 'Mesh',
                url: '/images/woman/sy/5.png',
            },
        ],
        pants: [
            {
                name: 'female_pants_0',
                label: '裤子1',
                type: 'TransformNode',
                url: '/images/woman/kz/1.png',
            },
            {
                name: 'female_pants_1',
                label: '裤子2',
                type: 'TransformNode',
                url: '/images/woman/kz/2.png',
            },
            {
                name: 'female_pants_2',
                label: '裤子3',
                type: 'TransformNode',
                url: '/images/woman/kz/3.png',
            },
            {
                name: 'female_pants_3',
                label: '裤子4',
                type: 'TransformNode',
                url: '/images/woman/kz/4.png',
            },
            {
                name: 'female_pants_4',
                label: '裤子5',
                type: 'TransformNode',
                url: '/images/woman/kz/5.png',
            },
        ],
        shoes: [
            {
                name: 'female_shoes_0',
                label: '鞋子1',
                type: 'Mesh',
                url: '/images/woman/xz/1.png',
            },
            {
                name: 'female_shoes_1',
                label: '鞋子2',
                type: 'TransformNode',
                url: '/images/woman/xz/7.png',
            },
            {
                name: 'female_shoes_2',
                label: '鞋子3',
                type: 'Mesh',
                url: '/images/woman/xz/2.png',
            },
            {
                name: 'female_shoes_3',
                label: '鞋子4',
                type: 'TransformNode',
                url: '/images/woman/xz/4.png',
            },
            {
                name: 'female_shoes_4',
                label: '鞋子5',
                type: 'Mesh',
                url: '/images/woman/xz/5.png',
            },
        ],
    },
};

export default modelData;
